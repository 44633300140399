import React from "react";
import classes from "./Header.module.scss";

import { MdArrowBack } from "react-icons/md";
import Logo from "../../assets/png/logo.png";

const Header = () => {
  return (
    <div className={classes.Header}>
      <div className={classes.NavController}>
        <div>
          <MdArrowBack />
        </div>
        <span>Back To Home</span>
      </div>
      <div className={classes.LogoContainer}>
        <a>
          <span>
            <div>
              <img src={Logo} alt="Billable" />
            </div>
          </span>
        </a>
      </div>
    </div>
  );
};

export default Header;
