import React from "react";
import classes from "./Form.module.scss";

const Form = () => {
  return (
    <div className={classes.Form}>
      <div className={classes.FormContainer}>
        <div className={classes.Header}>
          <h1>Pay Online</h1>
        </div>
        <div className={classes.FormWrapper}>
          <div className={classes.FormFields}>
            <div className={classes.FormHeader}>
              <span>Client Details</span>
              <input type="text" placeholder="Customer Name" />
              <input type="number" placeholder="Contact Number" />
              <input type="email" placeholder="Email Address" />
            </div>

            <div className={classes.ReferenceSection}>
              <span>Reference Number</span>
              <input type="number" placeholder="XXXX - XXX" />
              <textarea
                name="paymentNotes"
                id="paymentNotes"
                cols="30"
                rows="3"
                placeholder="Payment Notes (Optional)"
              ></textarea>
            </div>
          </div>
          <div className={classes.FormBody}>
            <div className={classes.AmountContainer}>
              <span>Amount</span>
              <input type="text" value="LKR 2,650.00" />
            </div>
          </div>
        </div>

        <div className={classes.Footer}>
          <p>Powered by Billable</p>
        </div>
      </div>
      <div className={classes.ButtonContainer}>
        <button>Pay Now</button>
      </div>
    </div>
  );
};

export default Form;
