import React from "react";

import Header from "./components/Header/Header";
import Backdrop from "./components/Backdrop/Backdrop";
import Form from "./components/Form/Form";

function App() {
  return (
    <div>
      <Header />
      {/* <Backdrop /> */}
      <Form />
    </div>
  );
}

export default App;
